body {
  margin: 0;
  padding: 0;
  font-family: "Teachers", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
a,
textarea {
  font-family: "Teachers", sans-serif !important;
}

html {
  margin: 0;
  padding: 0;
}

.constact-us-textarea::placeholder {
  color: #0000005c;
}

@media (min-width: 1000px) {
  .constact-us-textarea {
    width: 100% !important;
  }
}

* {
  scroll-behavior: smooth;
}